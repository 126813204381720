import React from "react";
import Logo from "../assets/imgs/Logo.png";
import Safe from "../assets/imgs/Safe.png";

const Splash = () => {
  return (
    <div className="flex flex-col items-center justify-center h-full">
      <img src={Logo} alt="Logo" className="h-40" />
    </div>
  );
};

export default Splash;
